import {TSMap} from "typescript-map";
import {BrandType} from "platform/enum/BrandType";

const MuiFonts: TSMap<BrandType, string[]> = new TSMap();
MuiFonts.set(BrandType.SeventyTrades, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.A3Trading, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Finansero, ["RobotoRegular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.FXGMZA, ["ComfortaaRegular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Fortissio, ["RobotoRegular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Ontega, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.TaoTrade, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.ToroInvest, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.TradoCenter, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.UBanker, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.WBSmith, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Wbsquantum, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.XLNTrade, ["Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Zenstox, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Sencillo, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.XCite, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Inviertas, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.ZenstoxInvest, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.ZenstoxQuantum, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Tradit, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Tradeapp, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.Invest200, ["Poppins-Regular", "Roboto", "Arial", "sans-serif"]);
MuiFonts.set(BrandType.TheBrainTrade, ["InterRegular", "Roboto", "Arial", "sans-serif"]);

export {
    MuiFonts
};
