enum BrandType {

    SeventyTrades = "_70Trades",
    A3Trading = "A3Trading",
    Finansero = "Finansero",
    Fortissio = "Fortissio",
    FXGMZA = "FXGM-za",
    Ontega = "Ontega",
    TaoTrade = "TaoTrade",
    ToroInvest = "ToroInvest",
    TradoCenter = "TradoCenter",
    UBanker = "UBanker",
    WBSmith = "WBSmith",
    XLNTrade = "XLNTrade",
    Zenstox = "Zenstox",
    ZenstoxInvest = "ZenstoxInvest",
    ZenstoxQuantum = "ZenstoxQuantum",
    Sencillo = "Sencillo",
    XCite = "XCite",
    Inviertas = "Inviertas",
    Tradit = "Tradit",
    Wbsquantum = "Wbsquantum",
    Tradeapp = "Tradeapp",
    Invest200 = "Invest200",
    TheBrainTrade = "TheBrainTrade",
}

namespace BrandType {

    export function deserialize(type: string): BrandType {
        if (type === "70Trades") {
            return BrandType.SeventyTrades;
        } else if (type === "FXGM-za" || type === "FXGM-ZA") {
            return BrandType.FXGMZA;
        } else if (type === "Warren Bowie & Smith") {
            return BrandType.WBSmith;
        }
        return BrandType[type] || type;
    }

    export function from(brand: string): BrandType {
        if (brand) {
            switch (brand) {
                case "70trades":
                    return BrandType.SeventyTrades;
                case "a3trading":
                    return BrandType.A3Trading;
                case "finansero":
                    return BrandType.Finansero;
                case "fortissio":
                    return BrandType.Fortissio;
                case "fxgmza":
                    return BrandType.FXGMZA;
                case "ontega":
                    return BrandType.Ontega;
                case "taotrade":
                    return BrandType.TaoTrade;
                case "toroinvest":
                    return BrandType.ToroInvest;
                case "tradocenter":
                    return BrandType.TradoCenter;
                case "ubanker":
                    return BrandType.UBanker;
                case "wbandsmith":
                    return BrandType.WBSmith;
                case "xlntrade":
                    return BrandType.XLNTrade;
                case "zenstox":
                    return BrandType.Zenstox;
                case "zenstoxinvest":
                    return BrandType.ZenstoxInvest;
                case "zenstoxquantum":
                    return BrandType.ZenstoxQuantum;
                case "sencillo":
                    return BrandType.Sencillo;
                case "xcite":
                    return BrandType.XCite;
                case "inviertas":
                    return BrandType.Inviertas;
                case "tradit":
                    return BrandType.Tradit;
                case "wbsquantum":
                    return BrandType.Wbsquantum;
                case "tradeapp":
                    return BrandType.Tradeapp;
                case "200invest":
                    return BrandType.Invest200;
                case "thebraintrade":
                    return BrandType.TheBrainTrade;
            }
        }
        return brand as BrandType;
    }

    export function folder(brandId: number): string {
        if (brandId) {
            switch (brandId) {
                case 39:
                    return "70trades";
                case 33:
                    return "a3trading";
                case 65:
                    return "finansero";
                case 41:
                    return "fortissio";
                case 56:
                    return "fxgmza";
                case 60:
                    return "ontega";
                case 66:
                    return "xcite";
                case 34:
                    return "taotrade";
                case 64:
                    return "toroinvest";
                case 62:
                    return "tradocenter";
                case 35:
                    return "ubanker";
                case 63:
                    return "wbandsmith";
                case 40:
                    return "xlntrade";
                case 67:
                    return "zenstox";
                case 68:
                    return "sencillo";
                case 71:
                    return "inviertas";
                case 72:
                    return "zenstoxinvest";
                case 73:
                    return "tradit";
                case 76:
                    return "wbsquantum";
                case 77:
                    return "tradeapp";
                case 75:
                    return "200invest";
                case 80:
                    return "zenstoxquantum";
                case 81:
                    return "thebraintrade";
            }
        }
        return null;
    }

    export function id(type: BrandType): number {
        if (type) {
            switch (type) {
                case BrandType.SeventyTrades:
                    return 39;
                case BrandType.A3Trading:
                    return 33;
                case BrandType.Finansero:
                    return 65;
                case BrandType.Fortissio:
                    return 41;
                case BrandType.FXGMZA:
                    return 56;
                case BrandType.Ontega:
                    return 60;
                case BrandType.XCite:
                    return 66;
                case BrandType.TaoTrade:
                    return 34;
                case BrandType.ToroInvest:
                    return 64;
                case BrandType.TradoCenter:
                    return 62;
                case BrandType.UBanker:
                    return 35;
                case BrandType.WBSmith:
                    return 63;
                case BrandType.XLNTrade:
                    return 40;
                case BrandType.Zenstox:
                    return 67;
                case BrandType.Sencillo:
                    return 68;
                case BrandType.Inviertas:
                    return 71;
                case BrandType.ZenstoxInvest:
                    return 72;
                case BrandType.Tradit:
                    return 73;
                case BrandType.Wbsquantum:
                    return 76;
                case BrandType.Tradeapp:
                    return 77;
                case BrandType.Invest200:
                    return 75;
                case BrandType.ZenstoxQuantum:
                    return 80;
                case BrandType.TheBrainTrade:
                    return 81;
            }
        }
        return null;
    }

    export function V2Mode(type: BrandType): boolean {
        if (type) {
            switch (type) {
                case BrandType.Sencillo:
                case BrandType.Tradeapp:
                    return true;
            }
        }
        return false;
    }
}

export {BrandType};
